.error-container {
    width: 70%;
    display: flex;
    position: relative;
    justify-content: center;
    margin: auto;
    margin-bottom: 168px;

    @media (max-width:800px) {
        width: 95%;
        flex-direction: column;
        margin-top:120px;
    }

    .error-img {
        position: absolute;

        @media (max-width:800px) {
            position:relative;
            width: 100%;
        }

        &:nth-child(1) {
            top: 80px;
            left: 0;
            @media (max-width:800px) {
                top:0;
            }
        }

        &:nth-child(2) {
            top: 184px;
            ;
            right: 0;

            @media (max-width:800px) {
                display: none;
            }
        }

        &:nth-child(3) {
            top: 64px;
            right: 166px;

            @media (max-width:800px) {
                display: none;
            }
        }
    }

    .error-txt-btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 48px;
        align-items: center;
        margin-top: 224px;

        @media (max-width:800px) {
            margin-top: 0;
        }

        .error-number {
            color: var(--fcolor-grey, #36454F);
            font-family: var(--secondary-font);
            font-size: 96px;
            font-weight: var(--fweight-bold);
            letter-spacing: 3.84px;
            text-transform: uppercase;
            @media (max-width:800px) {
                font-size: 48px;
            }
        }

        .error-txt {
            color: var(--fcolor-grey, #36454F);
            font-family: var(--secondary-font);
            font-size: 64px;
            font-weight: var(--fweight-bold);
            letter-spacing: 2.56px;
            text-transform: uppercase;
            @media (max-width:800px) {
                font-size: 28px;
                text-align: center;
            }
        }

        .error-btn {
            display: flex;
            width: 312px;
            height: 48px;
            padding: 16px 30px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: var(--fcolor-grey, #36454F);
            color: var(--white, #FFF);
            font-family: var(--primary-font);
            font-size: var(--fsize-medium);
            font-weight: var(--fweight-semibold);
            text-transform: capitalize;
            text-decoration: none;
            @media (max-width:800px) {
                width: 95%;
            }
        }
    }
}