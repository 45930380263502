@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Syne:wght@700&display=swap');
@import './variables.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@-moz-document url-prefix() {
  html {
    scroll-behavior: auto;
  }
}

body {
  max-width: 1440px;
  margin: 0 auto;
  font-family: var(--primary-font);
}


a,
a:visited {
  text-decoration: none;
  color: inherit;
}

li {
  list-style-type: none;
}