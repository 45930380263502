.gallery-container {
	position: relative;
	max-width: 1440px;
	min-height: 100dvh;
	background-color: #1C2321;
	padding: 72px;
	padding-top: 144px;


	.gallery-animation {
		margin: auto;
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
		gap: 36px;
	}

	.gallery-items {
		cursor: pointer;
		& img {
			border-radius: 10px;
		}
	}

	.close-page {
		position: absolute;
		top: 40px;
		right: 60px;
		color: #000;
		display: inline-flex;
		padding: 4px 16px;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 100px;
		font-family: var(--primary-font);
		font-size: var(--fsize-small);
		font-weight: var(--fweight-medium);
		text-transform: capitalize;
		cursor: pointer;

		&:active {
			transform: scale(0.95);
		}
	}
}