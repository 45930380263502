@import '../../styles/variables.css';


html {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.detail-container {
    display: flex;
    flex-direction: column;
    height: 100dvh;

    @media (max-width:800px) {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

.overflow-wrapper {
    overflow: hidden;
    position: relative;

    @media (max-width:800px) {
        min-height: 350px;
        max-height: 350px;
        margin: 24px;
    }

    .btn-close {
        content: 'Close';
        position: absolute;
        top: 24px;
        right: 64px;
        z-index: 10;
        display: inline-flex;
        height: 24px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 100px;
        color: var(--fcolor-white, #FFF);
        background: var(--fcolor-black, #1C2321);

        &:hover {
            cursor: pointer;
        }

        &:active {
            transform: scale(0.95);
        }
    }


    .gallery-exhibition {
        backface-visibility: hidden;
        display: flex;
        touch-action: pan-y;
        margin-right: 24px;

        .gallery-slide {
            height: fit-content;
            position: relative;
            margin-right: 24px;

            @media (max-width:800px) {
                margin-right: 24px;
            }

            & img,
            video {
                object-fit: cover;
                height: 75dvh;

                @media (max-width:800px) {
                    /* width: 375px; */
                    height: 350px;
                }
            }
        }
    }
}

.link-viewartwork {
    @media (max-width:800px) {
        margin: auto;
    }
}

.view-artwork {
    display: inline-flex;
    color: var(--fcolor-white, #FFF);
    font-family: var(--primary-font);
    font-size: var(--fsize-small);
    font-weight: var(--fweight-semibold);
    letter-spacing: 0.24px;
    text-transform: capitalize;
    border-radius: 100px;
    background: var(--fcolor-orange, #F16821);
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: fit-content;

    &:hover {
        cursor: pointer;
    }

    &:active {
        transform: scale(0.95);
    }

    @media(max-width:800px) {
        width: fit-content;
        margin: auto;
        margin-bottom: 24px;
    }
}

.container-exhibition {
    display: flex;
    flex-direction: column;
    padding: 24px 60px 24px 60px;
    background-color: #FFF;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width:800px) {
        padding: 0;
        margin: 24px;
        margin-bottom: 80px;
    }
}

.exhi-text-link {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;

    @media (max-width:800px) {
        flex-direction: column;
    }

    .exhi-text {
        color: var(--fcolor-grey, #36454F);
        font-family: var(--primary-font);
        font-size: var(--fsize-regular);
        font-weight: var(--fweight-regular);
        line-height: 28px;
        letter-spacing: 0.42px;
        width: 75%;

        @media (max-width:800px) {
            width: 100%;
            margin-bottom: 16px;
        }

        & p {
            margin-bottom: 28px;
        }
    }

    .exhi-links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: center;
        gap: 13px;

        @media (max-width:800px) {
            align-items: flex-start;
        }

        .exhi-link {
            color: var(--fcolor-grey, #36454F);
            font-family: var(--primary-font);
            font-size: var(--fsize-small);
            font-weight: var(--fweight-medium);
            text-transform: capitalize;
            display: flex;
            align-items: center;
            gap: 4px;

            &:hover {
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95);
            }
        }

    }
}

.exhi-title-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;


    @media (max-width:800px) {
        margin: 24px;
        margin-top: 32px;
        align-items: flex-end;
    }

    .exhi-title {
        color: var(--fcolor-orange, #F16821);
        font-family: var(--secondary-font);
        font-size: 48px;
        font-weight: var(--fweight-bold);
        letter-spacing: 1.92px;
        text-transform: uppercase;
        overflow-wrap: break-word;
        max-width: 50%;

        @media (max-width:1000px) {
            font-size: 36px;
        }

        @media (max-width:800px) {
            font-size: 30px;
            max-width: 80%;
        }
    }

    .exhi-btn-year {
        display: flex;
        flex-direction: row;
        gap: 32px;

        .exhi-btn {
            display: inline-flex;
            color: var(--fcolor-white, #FFF);
            font-family: var(--primary-font);
            font-size: var(--fsize-small);
            font-weight: var(--fweight-semibold);
            letter-spacing: 0.24px;
            text-transform: capitalize;
            border-radius: 100px;
            background: var(--fcolor-orange, #F16821);
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            height: fit-content;

            &:hover {
                cursor: pointer;
            }

            @media (max-width:800px) {
                display: none;
            }
        }

        .exhi-year {
            color: var(--fcolor-orange, #F16821);
            text-align: right;
            font-family: var(--primary-font);
            font-size: 32px;
            font-weight: var(--fweight-regular);
            letter-spacing: 0.32px;
            text-transform: capitalize;
            white-space: nowrap;
        }
    }

}


.maxdvh {
    max-height: 95dvh;
}

.detail-active {
    z-index: 1;
    border-radius: 16px 16px 0px 0px;
}

.detail-inactive {
    transform: translateY(0%);
}

.text-inactive {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width:800px) {
        display: flex;
        flex-direction: column;
    }
}

.text-active {
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.btn-active {
    .svg-icon {
        height: 20px;
        width: 20px;
        color: #FFF;
        background-image: url('../../assets/svg/remove.svg');
        background-size: cover;
        animation: slideDown 0.8s cubic-bezier(.08, .82, .17, 1);
        ;
    }

    &::before {
        content: 'Close Full Description';
        animation: slideDown 0.8s cubic-bezier(.08, .82, .17, 1);
    }
}

.btn-inactive {
    .svg-icon {
        height: 20px;
        width: 20px;
        color: #FFF;
        background-image: url('../../assets/svg/description.svg');
        background-size: cover;
        animation: slideUp 1s cubic-bezier(.08, .82, .17, 1);
    }

    &::before {
        content: 'Full Description';
        animation: slideUp 1s cubic-bezier(.08, .82, .17, 1);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}