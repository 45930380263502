@import '../../styles/variables.css';


.container-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    break-inside: avoid;
    max-width: 350px;
    margin: 16px;
    margin-top: 16px;
    @media (max-width:400px) {
        max-width: 320px;
    }

    .card-img {
        margin-bottom: 16px;

        & img {
            width: 350px;
            border-radius: 6px;

            @media (max-width:400px) {
                width: 300px;
            }
        }
    }

    @media (max-width:350px) {
        width: 300px;
    }

    .card-title-year {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card-title-desc {
            display: flex;
            flex-direction: column;
            max-width: 70%;


            .card-title {
                color: var(--fcolor-grey, #36454F);
                font-family: var(--primary-font);
                font-size: var(--fsize-large);
                font-weight: var(--fweight-semibold);
                letter-spacing: 0.36px;
                text-transform: capitalize;
                margin-bottom: 5px;
                overflow-wrap: break-word;
            }

            .card-desc {
                margin-top: 2px;
                color: var(--grey, #36454F);
                font-family: var(--primary-font);
                font-size: clamp(8px, 1.5vw, 16px);
                font-weight: var(--fweight-light);
                @media (max-width:800px) {
                    font-size: var(--fsize-medium);
                }
            }
        }

        .card-year {
            color: var(--fcolor-orange, #F16821);
            font-family: var(--primary-font);
            font-size: clamp(16px, 2vw, 28px);
            font-weight: var(--fweight-light);
            letter-spacing: var(--lspacing-link);
            text-transform: capitalize;
            white-space: nowrap;
            @media (max-width:800px) {
                font-size: 28px;
            }
        }
    }
}