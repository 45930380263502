@import '../../styles/variables.css';

.main-container-card {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin: 28px auto;

    @media (max-width:800px) {
        max-width: 100%;
        margin: 40px 24px;
    }

    .container-card-title {
        color: var(--fcolor-orange, #F16821);
        font-family: var(--secondary-font);
        font-size: clamp(52px, 5vw, 72px);
        font-weight: var(--fweight-bold);
        letter-spacing: 2.88px;
        text-transform: uppercase;
        margin-bottom: 48px;

        @media (max-width:800px) {
            font-size: 48px;
            margin-bottom: 20px;
        }
    }

    .container-all-card {

        @media (max-width:800px) {
            display: flex;
            flex-direction: column;
            gap: 32px;
            align-items: center;
        }
    }
    .Columned{
        margin: auto;
        & img{
            display: block;
            width: 100%;
        }

    }

}