.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 80px;

    .contact-text {
        text-align: start;
        color: var(--fcolor-grey, #36454F);
        font-family: var(--secondary-font);
        font-size: 36px;
        font-weight: var(--fweight-bold);
        text-transform: uppercase;
        margin-bottom: 32px;
        @media (max-width:800px) {
            font-size: 24px;
        }
    }

    .form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr fit-content 1fr;
        gap: 24px;
        align-items: center;

        @media (max-width:800px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: normal;
        }

        & input,
        textarea {
            color: #929292;
            border-radius: 8px;
            background: #F6F6F6;
            font-size: var(--fsize-regular);
            padding: 14px 16px;
            border: none;
        }

        .form-style {
            color: var(--fcolor-grey, #36454F);
            font-family: var(--primary-font);
            font-size: var(--fsize-medium);
            font-weight: var(--fweight-semibold);
            letter-spacing: 0.32px;
            text-transform: capitalize;
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        .name {
            grid-row: 1;
            grid-column: 1/3;
        }

        .telephone {
            grid-row: 2;
            grid-column: 1;
        }

        .email {
            grid-row: 2;
            grid-column: 2;
        }

        .text {
            grid-row: 3;
            grid-column: 1/3;

            & textarea {
                width: auto !important;
                padding: 16px 16px 72px 16px;
                resize: none;
                overflow: hidden;
            }
        }

        .form-button {
            color: var(--color-black, #1C2321);
            font-family: var(--primary-font);
            font-size: var(--fsize-medium);
            font-weight: var(--fweight-semibold);
            background: var(--fcolor-yellow, #FAAB35);
            grid-row: 4;
            grid-column: 1/3;

            &:hover {
                cursor: pointer;
            }

            &:active {
                transform: scale(0.95)
            }
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.success {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;

    .thanks {
        color: var(--fcolor-yellow, #FAAB35);
        font-family: var(--secondary-font);
        font-size: 64px;
        font-weight: var(--fweight-semibold);
        letter-spacing: 2.56px;
        text-transform: uppercase;
        margin-bottom: 16px;
        @media (max-width:800px) {
            font-size: 32px;
        }
    }

    .msg-success {
        text-align: center;
        color: var(--fcolor-grey, #36454F);
        font-family: var(--primary-font);
        font-size: 24px;
        font-weight: var(--fweight-medium);
        text-wrap: balance;
        line-height: 40px;
        @media (max-width:800px) {
            font-size: 16px;
        }
    }
}