.carousel-container {
    max-width: 1440px;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #1C2321;
    color: white;
    padding: 40px 60px;

    @media (max-width:700px) {
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    @media (max-height:500px) {
        flex-direction: row-reverse;
        padding: 0;
        justify-content: space-evenly;
    }

    .close-page {
        position: absolute;
        top: 40px;
        right: 60px;
        color: #000;
        display: inline-flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 100px;
        font-family: var(--primary-font);
        font-size: var(--fsize-small);
        font-weight: var(--fweight-medium);
        text-transform: capitalize;

        &:hover {
            cursor: pointer;
        }

        &:active {
            transform: scale(0.95);
        }

        @media (max-width:700px) {
            position: fixed;
            top: 24px;
            right: 24px;
        }

        @media (max-height:500px) {
            position: fixed;
            top: 24px;
            right: 24px;
        }

    }

    .carousel-arrow {
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 10px;
        border: none;
        border-radius: 100px;
        background: #ECECEC;
        color: #000;

        &:hover {
            cursor: pointer;
        }

        &:active {
            transform: scale(0.95);
        }

        @media (max-width:700px) {
            display: none;
        }

        @media (max-height:500px) {
            display: none;
        }
    }

    .artwork-container {
        max-width: 80%;
        display: flex;
        flex-direction: column;

        @media (max-width:700px) {
            margin-top: 80px;
            max-width: 100%;
        }

        @media (max-height:500px) {
            margin-right: 20px;
            margin-top: 5px;
        }
        @media (max-height:350px) {
            margin-top: 35px;
            max-width: 100%;
        }


        .artwork-picture {
            margin-bottom: 32px;
            display: flex;
            overflow: hidden;

            @media (max-height:500px) {
                margin-bottom: 8px;
                max-height: 300px;
            }
            @media (max-height:350px) {
                max-height: 220px;
            }

            & img {
                width: 100%;
                max-width: 1100px;
                max-height: 605px;
                object-fit: contain;
                animation: fadeImage 0.5s ease-in-out;

                @media (max-width:500px) {
                    max-height: 500px;
                }
            }
        }

        .artwork-text {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width:700px) {
                flex-direction: column;
                align-items: center;
                gap: 40px;
                margin-bottom: 40px;
                margin: auto;
            }

            @media (max-height:450px) {
                flex-direction: row;
                margin: auto;
                margin-bottom: 8px;
            }
            @media (max-width:425px) {
                width: 90%;
            }

            .artwork-desc {
                display: flex;
                flex-direction: column;

                @media (max-height:500px) {
                    flex-direction: row;
                    gap: 24px;
                    align-items: center;
                }


                .artwork-title {
                    color: var(--fcolor-white, #FFF);
                    font-family: var(--primary-font);
                    font-size: var(--fsize-large);
                    font-style: italic;
                    font-weight: var(--fweight-semibold);
                    margin-bottom: 8px;

                    @media (max-height:500px) {
                        margin-bottom: 0;
                    }
                }
            }

            .tech-size {
                display: flex;
                gap: 16px;
                color: var(--fcolor-white, #FFF);
                font-family: var(--primary-font);
                font-size: var(--fsize-regular);
                font-weight: var(--fweight-medium);

                .artwork-tech {
                    font-size: 12px;
                }

                .artwork-size {
                    font-size: 12px;
                }
            }

            .artwork-collection {
                font-size: 12px;
                margin-right: 16px;
            }

            .artwork-number {
                color: var(--white, #FFF);
                font-family: Montserrat;
                font-size: var(--fsize-large);
                font-weight: var(--fweight-semibold);
                white-space: nowrap;
            }

        }
    }
}


@keyframes fadeImage {
    0% {
        opacity: 0;
        transform: scale(0.90)
    }

    100% {
        opacity: 1;
        transform: scale(1.0);
    }
}