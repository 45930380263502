:root {
    --primary-font: 'Montserrat', sans-serif;
    --secondary-font: 'Syne', sans-serif;
    --fcolor-black:#000;
    --fcolor-white:#FFF;
    --fcolor-grey:#36454F;
    --fcolor-orange:#F16821;
    --btn-bg:#ECECEC;
    --fweight-regular: 400;
    --fweight-medium: 500;
    --fweight-semibold: 600;
    --fweight-bold: 700;
    --fsize-small:12px;
    --fsize-regular: 14px;
    --fsize-medium: 16px;
    --fsize-large: 18px;
    --lspacing-link: 0.3px;
    --lspacing-text: 0.42px;
}