
.react-pdf__Page {
	width: 300px;
	height: 400px;
	border-radius: 10px !important;
}

.react-pdf__Page__canvas {
	width: 300px !important;
	height: 400px !important;
	border-radius: 10px !important;
}

.react-pdf__Page__textContent {
	width: 300px !important;
	height: 400px !important;
}

.react-pdf__Page__annotations {
	width: 300px !important;
	height: 400px !important;
}

.react-pdf__Page__textContent {
	display: none;
}