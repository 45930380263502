@import '../../styles/variables.css';

.footer {
    background-color: #1C2321;
    padding: 45px 60px 28px 60px;
    @media (max-width:800px) {
        padding: 48px 24px;
    }

    .footer-container {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        color: var(--fcolor-white);
        @media (max-width:800px) {
            display: flex;
            flex-direction: column;
            gap:40px;
        }

        .footer-links {
            display: flex;
            flex-direction: column;
            gap: 24px;
            @media (max-width:800px) {
                gap:32px;
            }

            .footer-link {
                font-size: var(--fsize-medium);
                letter-spacing: var(--lspacing-link);
                color: white;
                text-decoration: none;
                font-family: var(--secondary-font);
            }
        }

        .contact-link {
            display: flex;
            flex-direction: column;
            gap:24px;
            @media (max-width:800px) {
                margin-top: 24px;
            }

            .contact-text {
                font-family: var(--secondary-font);
                font-size: var(--fsize-medium);
                font-weight: var(--fweight-bold);
                letter-spacing: var(--lspacing-link);
                @media (max-width:800px) {
                    font-size: 20px;
                }
            }

            .social-links {
                display: flex;
                gap: 12px;
                @media (max-width:800px) {
                    gap:16px
                }

                .social-link {
                    height: 44px;
                    width: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    border-radius: 8px;
                    &:hover{
                        cursor: pointer;
                    }
                    &:active{
                        transform: scale(0.95);
                    }
                }
            }
        }
    }

    .copyright {
        color: var(--fcolor-white);
        font-size:12px;
        font-weight: var(--fweight-regular);
        @media (max-width:800px) {
            margin-top:72px;
        }
    }
}