@import '../../styles/variables.css';

.header {
    display: flex;
    justify-content: space-between;
    margin: 8px 60px;
    align-items: center;
    box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.12);

    @media (max-width:800px) {
        position: sticky;
        background: #FFF;
        width: 100%;
        top: 0;
        z-index: 15;
        padding: 8px 24px;
        margin: 0;
    }

    .logo {
        &:active {
            transform: scale(0.95);
        }
    }

    .nav-links {
        display: flex;
        gap: 64px;

        @media (max-width:800px) {
            display: none;
        }

        .link {
            text-decoration: none;
            font-family: var(--secondary-font);
            font-size: var(--fsize-medium);
            font-weight: var(--fweight-bold);
            color: var(--fcolor-black);
            letter-spacing: var(--lspacing-link);

            &:active {
                transform: scale(0.95);
            }
        }
    }

    .menu-link {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .menu-bar {
            width: 35px;
            height: 3px;
            background-color: #000;
            border-radius: 5px;
        }

        .white {
            background-color: #FFF;
        }


        @media (min-width:801px) {
            display: none;
        }
    }

    .btn-close {

        :nth-child(1) {
            transform: rotate(45deg) translate(7px, 7px);
            transition: all ease-out 0.5s;
        }

        :nth-child(2) {
            transform: scale(0.05);
            transition: all ease-out 0.5s;
        }

        :nth-child(3) {
            transform: rotate(-45deg) translate(6px, -6px);
            transition: all ease-out 0.5s;

        }
    }

    .menu-open {
        display: flex;
        gap: 48px;
        flex-direction: column;
        align-items: center;
        position: absolute;
        padding-top: 120px;
        top: 44px;
        left: 0;
        width: 100vw;
        height: 100dvh;
        z-index: 10;
        background: #1C2321;
        animation: fadeIn 0.4s ease-in-out;
        transition-delay: 0.2;

        :nth-child(n) {
            color: var(--white, #FFF);
            text-align: center;
            font-family: var(--secondary-font);
            font-size: 24px;
            font-weight: var(--fweight-bold);
            animation: fadeOut 0.6s ease-in;
        }
    }

    .social-link-media {
        display: none;

        @media (max-width:800px) {
            margin-top: 80px;
            display: flex;
            gap: 24px;
        }

        .link-media {
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            border-radius: 10px;
        }

        @media (max-height:600px) {
            margin-top: 36px;
        }

    }
}

.header-black {
    margin: 0;
    padding: 8px 24px;
    width: 100%;
    background-color: #1C2321;
    animation: fadeIn 0.2s ease-in;
}


@keyframes fadeIn {
    0% {
        transform: scaleY(0.5);
        transform-origin: top;
    }

    100% {
        transform: scaleY(1);
        transform-origin: top;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}