.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @media (max-width:800px) {
        gap: 32px;
    }

    .hero-image {
        max-width: 100dvw;
        transition: opacity 0.8s linear;

        & img {
            width: 100%;
            height: auto;
            object-fit: cover;

            @media (max-width:800px) {
                min-height: 480px;
                object-position: -150px;
            }
        }
    }

    .image-out {
        opacity: 0.6;
    }

    .image-in {
        opacity: 1;
    }

    .hero-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 55%;
        color: var(--fcolor-grey, #36454F);
        font-family: var(--primary-font);
        font-size: 20px;
        font-weight: var(--fweight-medium);
        line-height: 40px;
        text-wrap: balance;

        @media (max-width:800px) {
            font-size: var(--fsize-medium);
            width: 90%;
        }

        & span {
            margin-top: 18px;
        }
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    /* margin-top: 48px; */

    .main-title {
        color: var(--fcolor-grey, #36454F);
        font-family: var(--secondary-font);
        font-size: 72px;
        font-weight: var(--fweight-bold);
        letter-spacing: 2.88px;
        text-transform: uppercase;
        text-align: center;

        @media (max-width:800px) {
            font-size: 48px;
            margin-bottom: 64px;
        }
    }
}