@import '../../styles/variables.css';

.project-container {
    display: flex;
    flex-direction: column;
    width: 92%;
    margin: 100px auto;

    @media (max-width:800px) {
        margin-top: 24px;
        margin-bottom: 0px;
    }

    .project-title-arrow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: #6E6E6E;


        .title-view {
            display: flex;
            gap: 32px;
            align-items: center;

            @media (max-width:800px) {
                width: 100%;
                justify-content: space-between;
                gap: 0;
            }


            .project-title {
                color: var(--fcolor-orange, #F16821);
                font-family: var(--secondary-font);
                font-size: 36px;
                font-weight: var(--fweight-bold);
                text-transform: uppercase;
            }

            .btn-viewall {
                display: flex;
                align-items: center;
                height: fit-content;
                background-color: var(--btn-bg, #ECECEC);
                padding: 7px 24px;
                border: none;
                border-radius: 100px;
                color: #36454F;
                font-family: var(--primary-font);
                font-size: var(--fsize-regular);
                font-weight: var(--fweight-semibold);
                letter-spacing: 0.28px;
                text-transform: capitalize;
                text-decoration: none;

                @media (max-width:800px) {
                    padding: 7px 16px;
                }

                & img {
                    margin-left: 4px;
                    height: 16px;
                    width: 16px;
                    color: #6E6E6E;
                }

                &:hover {
                    cursor: pointer;
                }

                &:active {
                    transform: scale(0.95);
                }
            }
        }

        .gallery-arrow {
            display: flex;
            gap: 16px;

            @media (max-width:800px) {
                display: none;
            }

            .arrow {
                display: flex;
                text-align: center;
                margin: auto;
                padding: 10px;
                background-color: var(--btn-bg, #ECECEC);
                border-radius: 100px;
                border: none;

                &:hover {
                    cursor: pointer;
                }

                &:active {
                    transform: scale(0.9);
                }
            }
        }
    }

    .separator {
        height: 1px;
        width: 100%;
        background-color: #ECECEC;
        margin-top: 16px;
        margin-bottom: 32px;
    }

    .project-gallery {
        display: flex;

        .card-container {
            width: 100%;
            display: flex;
            gap: 24px;
            scroll-behavior: smooth;
            scrollbar-width: none;
            overflow-y: hidden;
            overflow-x: scroll;
            overscroll-behavior-x: contain;

            &::-webkit-scrollbar {
                display: none;
            }

            &>div {
                flex: 0 0 auto;
                scroll-snap-align: start;

                & :last-child {
                    scroll-snap-align: end;
                }
            }

        }
    }
}