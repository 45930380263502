.container-about {
    display: flex;
    flex-direction: column;
    margin: 0 228px;

    @media (max-width:1000px) {
        margin: 0 128px;
    }

    @media (max-width:800px) {
        margin: 0 24px;
    }

    .section-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        @media (max-width:800px) {
            margin-bottom: 80px;
        }

        .title-about {
            color: var(--fcolor-yellow, #FAAB35);
            font-family: var(--secondary-font);
            font-size: 128px;
            font-weight: var(--fweight-bold);
            letter-spacing: 7.68px;
            text-transform: uppercase;

            @media (max-width:1000px) {
                font-size: 100px;
            }

            @media (max-width:800px) {
                font-size: 44px;
            }

            @media (max-width:375px) {
                font-size: 36px;
            }
        }

        .picture-about {
            margin-bottom: 32px;
            width: 1000px;
            height: 550px;
            border-radius: 10px;
            object-fit: cover;

            @media (max-width:1000px) {
                width: 90dvw;
            }

            @media (max-width:800px) {
                width: 90dvw;
            }
        }

        .desc-about {
            color: var(--fcolor-grey, #36454F);
            font-family: var(--primary-font);
            font-size: 20px;
            font-weight: var(--fweight-medium);
            line-height: 40px;
            margin-bottom: 40px;
            text-wrap: balance;
            text-align: center;

            @media (max-width:800px) {
                font-size: var(--fsize-medium);
            }
        }

        .desc-small-about {
            color: var(--fcolor-grey, #36454F);
            font-family: var(--primary-font);
            font-size: var(--fsize-medium);
            font-weight: var(--fweight-regular);
            line-height: 36px;
            text-wrap: balance;
            text-align: center;
            letter-spacing: 0.32px;

            @media (max-width:800px) {
                font-size: var(--fsize-regular);
            }
        }

        .view-article {
            display: inline-flex;
            margin-top: 40px;
            color: var(--fcolor-white, #FFF);
            font-family: var(--primary-font);
            font-size: var(--fsize-small);
            font-weight: var(--fweight-semibold);
            letter-spacing: 0.24px;
            text-transform: capitalize;
            border-radius: 100px;
            background: var(--fcolor-orange, #F16821);
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            height: fit-content;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .section-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .picture-title {
            position: relative;
            width: 100%;

            /* padding-top: 207px; */
            /* margin-bottom: 40px; */
            @media (max-width:800px) {
                padding-top: 0;
            }

            .img1 {
                position: relative;
                width: 100%;

                @media (max-width:800px) {
                    position: relative;
                    width: 100%;
                }
            }

            .title-contact {
                color: var(--yellow, #FAAB35);
                font-family: var(--secondary-font);
                font-size: 64px;
                font-weight: var(--fweight-semibold);
                letter-spacing: 2.56px;
                text-transform: uppercase;
                text-align: center;

                @media (max-width:800px) {
                    font-size: 48px;
                }
            }
        }

        .info-contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 84px;

            @media (max-width:800px) {
                justify-content: center;
                margin: 0;
            }

            .text-contact {
                color: var(--fcolor-grey, #36454F);
                text-align: center;
                font-family: Montserrat;
                font-size: var(--fsize-medium);
                font-weight: var(--fweight-regular);
                line-height: 36px;
                letter-spacing: 0.32px;
                text-transform: capitalize;
                margin-bottom: 24px;

                @media (max-width:800px) {
                    font-size: var(--fsize-regular);
                }
            }

            .media-mail {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 80px;
                padding: 12px 0px;

                .title-info {
                    color: var(--fcolor-grey, #36454F);
                    font-family: var(--primary-font);
                    font-size: var(--fsize-large);
                    font-weight: var(--fweight-semibold);
                    letter-spacing: 0.36px;
                    text-transform: capitalize;
                }

                .info-email {
                    margin-right: 180px;

                    @media (max-width:800px) {
                        margin-right: 80px;
                    }

                    @media (max-width:400px) {
                        margin-right: 60px;
                    }
                }

                .info-social {
                    margin-right: 115px;

                    @media (max-width:800px) {
                        margin-right: 40px;
                    }
                }

                & p {
                    color: var(--grey, #36454F);
                    font-family: var(--primary-font);
                    font-size: 36px;
                    font-weight: var(--fweight-semibold);
                    line-height: 200%;
                    letter-spacing: 1.08px;

                    @media (max-width:800px) {
                        font-size: 16px;
                    }
                }

                .btn-social {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    & div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 42px;
                        width: 42px;
                        background-color: #36454F;
                        border-radius: 8px;

                        &:hover {
                            cursor: pointer;
                        }

                        &:active {
                            transform: scale(0.95);
                        }
                    }
                }


            }

            .separateur {
                width: 100%;
                height: 1px;
                background-color: #c5c5c5;

                &:nth-of-type(4) {
                    margin-bottom: 120px;
                }
            }
        }

    }

}